/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"
import Layout from "../components/layout"

const TOS = () => (
  <Layout>
    <div
      css={{
        fontSize: 14,
        padding: 20,
        maxWidth: 800,
        whiteSpace: "initial",
        margin: "0 auto",
        h1: {
          fontSize: 30,
        },
        p: {
          fontWeight: 300,
          fontFamily: "Helvetica",
        },
        ul: {
          fontWeight: 300,
          fontFamily: "Helvetica",
        },
      }}
    >
      <h1>Terms of Service</h1>
      <p>
        Welcome to www.begrizzlee.com (the "Site"), a website provided by
        Seamount Inc., the parent company of Be Grizzlee ("Company"). Your use
        of the Site is at all times subject to the following terms and
        conditions, and you are deemed to have accepted these terms of service
        upon using the Site. You should review these terms of service carefully,
        and be sure you understand them, prior to using the Site. If you do not
        agree to these terms of service, you must immediately exit the Site.
      </p>

      <p>
        1. <strong>Scope of Use</strong>
      </p>

      <blockquote>
        <p>
          The Site is provided for promotional and informational purposes only,
          and Company does not provide any client services through the Site. You
          may only use the Site if you are a current or prospective customer of
          Company, or otherwise interested in learning more about Company for a
          legitimate business purpose. The Site may not be used for any
          unlawful, fraudulent, harassing, objectionable or other non-legitimate
          business purpose.
        </p>
      </blockquote>

      <p>
        2. <strong>Site Content</strong>
      </p>

      <blockquote>
        <p>
          The Site and all content on the Site (collectively "Content"), plus
          all copyrights, trademarks, service marks and other intellectual
          property rights in the Site and such Content, are owned or licensed by
          Company. You may access, use and copy Content only as expressly
          permitted herein. No provision of these terms of service, and nothing
          contained on the Site, grants to you, whether expressly, implicitly or
          otherwise, any license or other right to copy, disclose, distribute,
          retransmit, use or create derivative works of any Content without the
          written permission of Company or its licensee (as the case may be),
          and any such copying, disclosure, distribution, retransmission, use or
          creation of derivative works is strictly prohibited, except as
          expressly set forth herein.
        </p>
      </blockquote>

      <p>
        3. <strong>Third-Party Websites</strong>
      </p>

      <blockquote>
        <p>
          The Site may contain links to websites maintained by third parties
          (individually a "Third-Party Site"). Company is not responsible for
          any content on a Third-Party Site (including, but not limited to, any
          opinions contained on a Third-Party Site), and does not necessarily
          endorse or otherwise approve of such content. No link from the Site to
          a Third-Party Site, or from a Third-Party Site to the Site, is an
          endorsement, sponsorship or recommendation by Company of such
          Third-Party Site, and the link is provided only for your convenience.
          In addition, your use of a Third-Party Site will be subject to its
          terms of use and other provisions, for which you are responsible if
          you proceed to such Third-Party Site.
        </p>
      </blockquote>

      <p>
        4. <strong>Privacy Policy</strong>
      </p>

      <blockquote>
        <p>
          Company may collect certain personal and analytical information from
          you, among other ways, through the Site, and will handle such
          information in accordance with its privacy policy, which can also be
          located on the Site (the "Privacy Policy"). You should contact Company
          as set forth in the Privacy Policy with all complaints, questions and
          requests for additional information relating to Company’s handling of
          such information.
        </p>
      </blockquote>

      <p>
        5. <strong>DMCA Notice</strong>
      </p>

      <blockquote>
        <p>
          If you believe that any content posted by you on the Site has been
          copied or otherwise used in a manner that constitutes copyright
          infringement, you may notify Company’s designated agent pursuant to
          the Digital Millennium Copyright Act of 1998 ("DMCA"). The name and
          contact information for Company’s designated agent for purposes of
          DMCA are set forth in Section 15. For your notice to be valid under
          DMCA, you must provide the following with respect to your claim of
          copyright infringement:
        </p>
      </blockquote>

      <blockquote>
        <ul>
          <li>
            A physical or electronic signature of a person authorized to act on
            behalf of the copyright owner;
          </li>
          <li>
            Identification of the material that is claimed to be infringing or
            to be the subject of the infringing activity, and that is to be
            removed or access to which is to be disabled, as well as information
            reasonably sufficient to permit Company to locate the material;
          </li>
          <li>
            Information reasonably sufficient to permit Company to contact the
            complaining party, such as an address, telephone number, and if
            available, an e- mail address;
          </li>
          <li>
            A statement that the complaining party has a good faith belief that
            use of the material in the manner complained of is not authorized by
            the copyright owner, its agent, or applicable law; and
          </li>
          <li>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that the complaining party is
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed.
          </li>
        </ul>
      </blockquote>

      <p>
        6. <strong>Disclaimer of Warranties</strong>
      </p>

      <blockquote>
        <p>
          THE SITE AND ALL CONTENT ARE PROVIDED "AS IS" AND WITHOUT ANY WARRANTY
          OR REPRESENTATION OF ANY KIND, WHETHER EXPRESS OR IMPLIED (INCLUDING,
          BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
          FITNESS FOR A PARTICULAR PURPOSE). WITHOUT LIMITING THE GENERALITY OF
          THE IMMEDIATELY PRECEDING SENTENCE, COMPANY DOES NOT WARRANT OR
          REPRESENT THAT THE SITE OR ANY CONTENT (A) WILL ALWAYS BE AVAILABLE
          FOR USE, (B) ARE FREE FROM SPYWARE, MALWARE, ADWARE, VIRUSES, WORMS OR
          OTHER MALICIOUS CODE, (C) WILL MEET YOUR REQUIREMENTS, (D) DO NOT
          INFRINGE THE INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY, OR (E) ARE
          ERROR-FREE OR THAT ANY DEFECTS THEREIN WILL BE CORRECTED.
        </p>
      </blockquote>

      <p>
        7. <strong>Limitation on Liability</strong>
      </p>

      <blockquote>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY SHALL NOT HAVE ANY
          LIABILITY FOR (A) ANY PERSONAL INJURY, OR (B) ANY DIRECT, INCIDENTAL,
          SPECIAL, INDIRECT, CONSEQUENTIAL OR OTHER DAMAGES WHATSOEVER
          (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, LOSS OF
          DATA, OR COMPUTER CRASHES OR OTHER DENIALS OF SERVICES) ARISING FROM
          OR RELATED TO YOUR USE OF, OR INABILITY TO USE, THE SITE, OR ANY
          CONTENT THEREON, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY
          (INCLUDING, BUT NOT LIMITED TO, BREACH OF CONTRACT, TORT, OR OTHER),
          AND EVEN IF COMPANY KNEW, OR SHOULD HAVE KNOWN, OF THE POSSIBILITY OF
          SUCH DAMAGES.
        </p>
      </blockquote>

      <p>
        8. <strong>Indemnification</strong>
      </p>

      <blockquote>
        <p>
          You must indemnify, defend and hold harmless Company, its subsidiaries
          and other affiliates, and its officers, employees and other agents,
          from all costs, expenses, damages, liabilities, losses and other
          monetary payments (including, but not limited to, attorneys' fees and
          disbursements) in connection with (a) your access to, and use of, the
          Site, (b) your failure to perform any obligation pursuant to these
          terms of service, (c) your violation of any rights of a third party
          (including, but not limited to, infringement of a third party’s
          intellectual property rights, or rights of privacy and publicity, and
          claims of defamation), and (d) any information or other content
          submitted by you to Company through the Site.
        </p>
      </blockquote>

      <p>
        9. <strong>Information Provided by You</strong>
      </p>

      <blockquote>
        <p>
          You grant to Company a non-exclusive, perpetual and fully-paid license
          to copy, distribute, modify, and create derivative works of all
          information and other content submitted by you to Company through the
          Site. All information provided to you through the Site must be
          accurate and complete in all respects, unless by its nature such
          information is not intended to be accurate or complete. In addition,
          you must at all times immediately update any such information to
          maintain its accuracy and completeness.
        </p>
      </blockquote>

      <p>
        10. <strong>Applicable Law</strong>
      </p>

      <blockquote>
        <p>
          These terms of service will be governed by, and construed in
          accordance with, the laws of the State of New York, without regard to
          principles of conflict of laws.
        </p>
      </blockquote>

      <p>
        11. <strong>Disputes</strong>
      </p>

      <blockquote>
        <p>
          Except for any dispute covered by the Privacy Policy, you and Company
          (a) will submit any dispute relating to the Site, any Content or these
          terms of service exclusively to a federal, state or local court
          located in the county of New York, New York and having subject matter
          jurisdiction over such dispute, and (b) consent to any such court
          being a proper venue, and waive any objection to its not being a
          proper venue (including, but not limited to, any such objection based
          on convenience), for such dispute.
        </p>
      </blockquote>

      <p>
        12. <strong>Entire Agreement</strong>
      </p>

      <blockquote>
        <p>
          These terms of service contain the entire agreement, and supersede all
          prior oral and written proposals, understandings and agreements,
          between you and Company with respect to the Site and any Content.
        </p>
      </blockquote>

      <p>
        13. <strong>Severability</strong>
      </p>

      <blockquote>
        <p>
          Whenever possible, each provision of these terms of service shall be
          interpreted to be effective and valid under applicable law. If,
          however, any such provision shall be prohibited by or invalid under
          such law, it shall be deemed modified to conform to the minimum
          requirements of such law, or if for any reason it is not so modified,
          it shall be prohibited or invalid only to the extent of such
          prohibition or invalidity without the remainder of such provision, or
          any other provision of these terms of service, being prohibited or
          invalid.
        </p>
      </blockquote>

      <p>
        14. <strong>Revisions</strong>
      </p>

      <blockquote>
        <p>
          Company may revise these terms of service from time to time by posting
          the revised terms of service on the Site, with any revision taking
          effect as of the date of such posting. It is your responsibility to
          periodically check these terms of service on the Site for such
          revisions. Your use of the Site following the posting of any revisions
          to these terms of service on the Site will be deemed an irrevocable
          acceptance by you of such revisions.
        </p>
      </blockquote>

      <p>
        15. <strong>Additional Information</strong>
      </p>

      <blockquote>
        <p>
          If you have any questions or desire additional information regarding
          the Site, any Content or these terms of service, or if you need to
          contact Company for any other reason, please write to:{" "}
          <a href="mailto:legal@begrizzlee.com">legal@begrizzlee.com</a>
        </p>
      </blockquote>

      <p>Effective Date: February 28, 2020</p>
    </div>
  </Layout>
)

export default TOS
